const ENV = {
  local: {
    base_url: "http://127.0.0.1:8000",
    site_url: "http://localhost:3000",
  },
  production1: {
    base_url: "https://foto-api.treeweb.top",
    site_url: "https://foto.treeweb.top",
  },
  production2: {
    base_url: "https://foto2-api.treeweb.top",
    site_url: "https://foto2.treeweb.top",
  },
  production_es: {
    base_url: "https://fotospain-api.treeweb.top",
    site_url: "https://fotospain.treeweb.top",
  },
};

const urls = ENV["production2"];

export const BASE_URL = urls.base_url;
export const SITE_URL = urls.site_url;
